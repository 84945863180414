import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';
import { EthersContext } from '../EthersProvider';
import { getContract } from '../utils/ethers';
import {
  DEX_ABI,
  DEX_ADDRESS,
  NAS_TOKEN_ABI,
  NAS_TOKEN_ADDRESS,
  USDT_TOKEN_ABI,
  USDT_TOKEN_ADDRESS,
  AQUARIUM_ABI,
  AQUARIUM_ADDRESS
} from '../utils/contracts';
import backgroundImage from '../images/dex_background.png';
import NasImage from '../images/nas_token_27.png';
import UsdtImage from '../images/usdt_27.png';
import './DEX.css';

const DEX = () => {
  const { provider, signer } = useContext(EthersContext);
  const [fromToken, setFromToken] = useState('NAS');
  const [toToken, setToToken] = useState('USDT');
  const [amount, setAmount] = useState('');
  const [estimatedAmount, setEstimatedAmount] = useState('0');
  const [slippage, setSlippage] = useState(0.5);
  const [gasEstimate, setGasEstimate] = useState('0');
  const [balances, setBalances] = useState({ NAS: '0', USDT: '0' });
  const [isLoading, setIsLoading] = useState(false);
  const [recentTxs, setRecentTxs] = useState([]);
  const [prices, setPrices] = useState({ NAS: '0', Na: '0', USDT: '1' });
  const [maxSwapAmount, setMaxSwapAmount] = useState('10000');
  const [showFee, setShowFee] = useState(false);

  useEffect(() => {
    if (signer) {
      updateBalances();
      fetchRecentTxs();
      fetchMaxSwapAmount();
    }
    updatePrices();
    const intervalId = setInterval(updatePrices, 30000); // 30초마다 가격 업데이트
    return () => clearInterval(intervalId);
  }, [signer]);

  useEffect(() => {
    if (amount && amount !== '0') {
      estimateSwap();
    } else {
      setEstimatedAmount('0');
      setGasEstimate('0');
    }
  }, [fromToken, toToken, amount]);

  const updateBalances = async () => {
    const nasToken = getContract(NAS_TOKEN_ADDRESS, NAS_TOKEN_ABI, signer);
    const usdtToken = getContract(USDT_TOKEN_ADDRESS, USDT_TOKEN_ABI, signer);
    const address = await signer.getAddress();

    const nasBalance = await nasToken.balanceOf(address);
    const usdtBalance = await usdtToken.balanceOf(address);

    setBalances({
      NAS: ethers.formatUnits(nasBalance, 18),
      USDT: ethers.formatUnits(usdtBalance, 6)
    });
  };

  const updatePrices = async () => {
    try {
      const aquariumContract = getContract(AQUARIUM_ADDRESS, AQUARIUM_ABI, provider);
      const nasPrice = await aquariumContract.getPrice();
      const totalSupply = await aquariumContract.totalSupply();
      const nasBalance = await aquariumContract.nasToken().balanceOf(AQUARIUM_ADDRESS);
      const usdtBalance = await aquariumContract.usdtToken().balanceOf(AQUARIUM_ADDRESS);
      
      const naPrice = totalSupply.isZero() ? 0 : (usdtBalance.mul(2).mul(ethers.parseUnits("1", 18))).div(totalSupply);
  
      setPrices({
        NAS: ethers.formatUnits(nasPrice, 18),
        Na: ethers.formatUnits(naPrice, 18),
        USDT: '1'
      });
    } catch (error) {
      console.error('가격 정보 가져오기 실패:', error);
    }
  };

  const fetchMaxSwapAmount = async () => {
    const dexContract = getContract(DEX_ADDRESS, DEX_ABI, provider);
    const maxAmount = await dexContract.maxSwapAmount();
    setMaxSwapAmount(ethers.formatUnits(maxAmount, 6));  // USDT has 6 decimals
  };

  const estimateSwap = async () => {
    if (!signer) return;

    const dexContract = getContract(DEX_ADDRESS, DEX_ABI, signer);
    try {
      const estimatedAmount = await dexContract.getAmountOut(
        fromToken === 'NAS' ? NAS_TOKEN_ADDRESS : USDT_TOKEN_ADDRESS,
        toToken === 'NAS' ? NAS_TOKEN_ADDRESS : USDT_TOKEN_ADDRESS,
        ethers.parseUnits(amount, fromToken === 'NAS' ? 18 : 6)
      );
      setEstimatedAmount(ethers.formatUnits(estimatedAmount, toToken === 'NAS' ? 18 : 6));

      const gasEstimate = await dexContract.estimateGas.swap(
        fromToken === 'NAS' ? NAS_TOKEN_ADDRESS : USDT_TOKEN_ADDRESS,
        toToken === 'NAS' ? NAS_TOKEN_ADDRESS : USDT_TOKEN_ADDRESS,
        ethers.parseUnits(amount, fromToken === 'NAS' ? 18 : 6),
        0 
      );
      setGasEstimate(ethers.formatUnits(gasEstimate, 'gwei'));
    } catch (error) {
      console.error('거래 금액 계산 중 오류가 발생했습니다.:', error);
    }
  };

  const handleSwap = async () => {
    if (!signer) {
      alert('지갑을 연결해주세요.');
      return;
    }

    setIsLoading(true);
    setShowFee(true);
    const dexContract = getContract(DEX_ADDRESS, DEX_ABI, signer);
    try {
      const minAmountOut = ethers.parseUnits(estimatedAmount, toToken === 'NAS' ? 18 : 6)
        .mul(100 - Math.floor(slippage * 100))
        .div(100);

      const tx = await dexContract.swap(
        fromToken === 'NAS' ? NAS_TOKEN_ADDRESS : USDT_TOKEN_ADDRESS,
        toToken === 'NAS' ? NAS_TOKEN_ADDRESS : USDT_TOKEN_ADDRESS,
        ethers.parseUnits(amount, fromToken === 'NAS' ? 18 : 6),
        minAmountOut
      );
      await tx.wait();
      alert('거래가 성공적으로 완료되었습니다.');
      updateBalances();
      fetchRecentTxs();
    } catch (error) {
      console.error('거래 중 오류가 발생했습니다:', error);
      alert('거래에 실패. 다시 거래를 진행해주세요.');
    } finally {
      setIsLoading(false);
      setTimeout(() => setShowFee(false), 3000);
    }
  };

  const fetchRecentTxs = async () => {
    if (!provider) return;
  
    const dexContract = getContract(DEX_ADDRESS, DEX_ABI, provider);
    
    try {
      // 최근 100개의 블록에서 이벤트를 조회합니다
      const filter = dexContract.filters.TokenSwapped();
      const blockNumber = await provider.getBlockNumber();
      const events = await dexContract.queryFilter(filter, blockNumber - 100, blockNumber);
  
      const transactions = events.map(event => ({
        txHash: event.transactionHash,
        user: event.args.user,
        tokenIn: event.args.tokenIn,
        tokenOut: event.args.tokenOut,
        amountIn: ethers.formatUnits(event.args.amountIn, 18),
        amountOut: ethers.formatUnits(event.args.amountOut, 18)
      }));
  
      setRecentTxs(transactions);
    } catch (error) {
      console.error('최근 거래 내역 조회 중 오류 발생:', error);
    }
  };

  const handleSwitchTokens = () => {
    setFromToken(toToken);
    setToToken(fromToken);
    setAmount('');
    setEstimatedAmount('0');
  };

  const TokenSelect = ({ value, onChange, options }) => (
    <div className="token-select">
      <img 
        src={value === 'NAS' ? NasImage : UsdtImage} 
        alt={value} 
        className={`token-icon ${value === 'NAS' ? 'nas-icon' : ''}`} 
      />
      <select value={value} onChange={onChange}>
        {options.map(option => (
          <option key={option} value={option}>
            {option} {option === 'USDT' && '(폴리곤)'}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="dex">
      <div className="back-image">
        <img className="dexback-img" alt="Dexback Image" src={backgroundImage} />
      </div>
      <div className="content-wrapper">
        <div className="dex-swap">Swap 스왑</div>
        <h2>탈중앙화 거래소</h2>
        <div className="fast-transfer">빠르게 토큰과 코인을 거래할 수 있는 곳</div>
        <div className="dex-box">
          <div className="info-container">
            <div className="price-info">
              <p>NAS 가격: {prices.NAS} USDT</p>
              <p>USDT 가격: {prices.USDT} 달러</p>
              <p>Na 가격: {prices.Na} USDT</p>
            </div>
            <div className="balance-info">
              <p>NAS 잔액: {balances.NAS}</p>
              <p>USDT 잔액: {balances.USDT}</p>
            </div>
          </div>
          <div className="swap-form">
            <TokenSelect
              value={fromToken}
              onChange={(e) => setFromToken(e.target.value)}
              options={['NAS', 'USDT']}
            />
            <div className="amount-switch-container">
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="수량"
                className="amount-input"
              />
              <button onClick={handleSwitchTokens} className="switch-button">토큰 변경</button>
            </div>
            <TokenSelect
              value={toToken}
              onChange={(e) => setToToken(e.target.value)}
              options={['NAS', 'USDT']}
            />
            <div className="estimated-amount">
              <p>예상 거래 금액: {estimatedAmount}</p>
            </div>
            <div className="gas-slippage">
              <div className="gas-estimate">
                <label>예상 가스비:</label>
                <span>{gasEstimate} Gwei</span>
              </div>
              <div className="slippage-input">
                <label htmlFor="slippage">슬리피지 (%):</label>
                <input
                  id="slippage"
                  type="number"
                  value={slippage}
                  onChange={(e) => setSlippage(Number(e.target.value))}
                  placeholder="슬리피지 %"
                />
              </div>
            </div>
            <p className="max-swap-amount">최대 거래 금액: {parseFloat(maxSwapAmount).toFixed(2)} USDT</p>
            <button onClick={handleSwap} disabled={isLoading} className="swap-button">
              {isLoading ? '거래 진행 중...' : '거래하기'}
            </button>
            {showFee && <p className="fee-info">수수료 0.5%</p>}
          </div>
        </div>
        <div className="recent-transactions">
          <h3>✔︎ 최근 거래 내역</h3>
          <div className="transactions-box">
            <ul>
              {recentTxs.map((tx, index) => (
                <li key={index}>
                  <span className="tx-hash">거래 해시: {tx.txHash.slice(0, 10)}...</span>
                  <span className="tx-tokens">{tx.tokenIn === NAS_TOKEN_ADDRESS ? 'NAS' : 'USDT'} → {tx.tokenOut === NAS_TOKEN_ADDRESS ? 'NAS' : 'USDT'}</span>
                  <span className="tx-amount">금액: {tx.amountIn} → {tx.amountOut}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Link to="/" className="main-link">홈 화면으로 돌아가기</Link>
    </div>
  );
};

export default DEX;