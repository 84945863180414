import React, { useState, useEffect } from 'react';
import { connectWallet, disconnectWallet, initProvider, switchToPolygon, getAddress } from '../utils/walletUtils';
import './WalletButton.css';

const WalletButton = ({ className }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState(null);

  // 7, 8번 요구사항: 자동 연결 제거
  useEffect(() => {
    const init = async () => {
      await initProvider();
    };
    init();
  }, []);

  const handleWalletConnection = async () => {
    if (isConnected) {
      // 6번 요구사항: 실제로 지갑 연결 해제
      await disconnectWallet();
      setIsConnected(false);
      setAddress(null);
    } else {
      const connected = await connectWallet();
      if (connected) {
        setIsConnected(true);
        const addr = await getAddress();
        setAddress(addr);
        await switchToPolygon();
      }
    }
  };

  return (
    <div className={`wallet-button ${className}`}>
      <button onClick={handleWalletConnection}>
        {isConnected ? '지갑 해제하기' : '지갑 연결하기'}
      </button>
        {isConnected && <p className="wallet-address">연결 주소: {address}</p>}
    </div>
  );
};

export default WalletButton;