import { ethers } from 'ethers';
import AquariumABI from '../abis/AquariumABI.json';
import NASTokenABI from '../abis/NASTokenABI.json';
import USDTTokenABI from '../abis/USDTTokenABI.json';
import ReferralABI from '../abis/ReferralABI.json';
import DexABI from '../abis/DexABI.json';
import RewardPoolABI from '../abis/RewardPoolABI.json';

export const AQUARIUM_ADDRESS = "0xE7dF801a1e3b84F57E93393e69190e36C40c7087";  
export const NAS_TOKEN_ADDRESS = "0xE75FC1097a7b4fd226f3b644d893e12E10F88b7C";
export const USDT_TOKEN_ADDRESS = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f";
export const REFERRAL_ADDRESS = "0xbAA160C6282FC6E7482a639b0C20ecA5E3Ed33f0";
export const DEX_ADDRESS = "0x90DD98884EDBB7cbF71ed21e9F4d36f25CaEDF97";  
export const REWARD_POOL_ADDRESS = "0x10BaC74fefB9E45899AefAFE9339f8C09da1c460";  

export const AQUARIUM_ABI = AquariumABI;
export const NAS_TOKEN_ABI = NASTokenABI;
export const USDT_TOKEN_ABI = USDTTokenABI;
export const REFERRAL_ABI = ReferralABI;
export const DEX_ABI = DexABI;
export const REWARD_POOL_ABI = RewardPoolABI;

const { formatUnits, parseUnits } = ethers;

export const getAquariumContract = (signer) => {
  return new ethers.Contract(AQUARIUM_ADDRESS, AQUARIUM_ABI, signer);
};

export const getNASTokenContract = (signer) => {
  return new ethers.Contract(NAS_TOKEN_ADDRESS, NAS_TOKEN_ABI, signer);
};

export const getUSDTTokenContract = (signer) => {
  return new ethers.Contract(USDT_TOKEN_ADDRESS, USDT_TOKEN_ABI, signer);
};

console.log("Contract addresses:");
console.log("Aquarium:", AQUARIUM_ADDRESS);
console.log("NAS Token:", NAS_TOKEN_ADDRESS);
console.log("USDT Token:", USDT_TOKEN_ADDRESS);

export const addLiquidity = async (signer, usdtAmount, miningDuration) => {
  console.log("addLiquidity 함수 시작");
  console.log("USDT 금액:", usdtAmount);
  console.log("채굴 기간:", miningDuration.toString());

  const usdtAmountWei = ethers.parseUnits(usdtAmount, 6);
  console.log("USDT 금액 (Wei):", usdtAmountWei.toString());
  
  const aquarium = getAquariumContract(signer);
  const nasToken = getNASTokenContract(signer);
  const usdtToken = getUSDTTokenContract(signer);
  const userAddress = await signer.getAddress();
  console.log("사용자 주소:", userAddress);

// 각 단계마다 상세한 로그 추가
  const minLiquidityAmount = await aquarium.minLiquidityAmount();
  console.log("최소 유동성 금액:", ethers.formatUnits(minLiquidityAmount, 6));

  if (usdtAmountWei < minLiquidityAmount) {
    throw new Error(`USDT amount must be at least ${ethers.formatUnits(minLiquidityAmount, 6)} USDT`);
  }

  const lockPeriod = await aquarium.LOCK_PERIOD();
  const maxMiningDuration = await aquarium.MAX_MINING_DURATION();
  console.log("Lock period:", lockPeriod.toString());
  console.log("Max mining duration:", maxMiningDuration.toString());
  console.log("Requested mining duration:", miningDuration);

  if (BigInt(miningDuration) < lockPeriod || BigInt(miningDuration) > maxMiningDuration) {
    throw new Error(`Mining duration must be between ${lockPeriod} and ${maxMiningDuration} seconds`);
  }

  try {
    // NAS 금액 계산
    const nasRatio = await aquarium.nasRatio();
    const usdtRatio = await aquarium.usdtRatio();
    const nasAmount = (BigInt(usdtAmountWei) * BigInt(nasRatio)) / BigInt(usdtRatio);
    console.log("Required NAS amount:", ethers.formatUnits(nasAmount, 18));
    
    // NAS 토큰 승인
    console.log("Approving NAS transfer...");
    const nasApprovalTx = await nasToken.approve(AQUARIUM_ADDRESS, nasAmount);
    await nasApprovalTx.wait();
    console.log("NAS transfer approved");

    // USDT 토큰 승인
    console.log("Approving USDT transfer...");
    const usdtApprovalTx = await usdtToken.approve(AQUARIUM_ADDRESS, usdtAmountWei);
    await usdtApprovalTx.wait();
    console.log("USDT transfer approved");

    console.log("Adding liquidity...");
    const addLiquidityTx = await aquarium.addLiquidity.populateTransaction(
      userAddress, 
      usdtAmountWei, 
      BigInt(miningDuration)
    );
    console.log("Populated transaction:", addLiquidityTx);

    const tx = await signer.sendTransaction({
      ...addLiquidityTx,
      gasLimit: 5000000
    });

    console.log("Transaction sent:", tx.hash);
    const receipt = await tx.wait();
    
    if (receipt.status === 0) {
      console.error("Transaction failed. Receipt:", receipt);
      throw new Error("Transaction failed");
    }

    console.log("Liquidity added, transaction hash:", receipt.transactionHash);
    return receipt.transactionHash;
  } catch (error) {
    console.error("Error in addLiquidity:", error);
    if (error.transaction) {
      console.error("Failed transaction data:", error.transaction);
    }
    if (error.reason) {
      console.error("Error reason:", error.reason);
    }
    if (error.code) {
      console.error("Error code:", error.code);
    }
    if (error.error && error.error.message) {
      console.error("Inner error message:", error.error.message);
    }
    throw error;
  }
};  

// 트랜잭션 실패 이유를 얻기 위한 새로운 함수
async function getTransactionFailureReason(provider, txHash) {
  try {
    const tx = await provider.getTransaction(txHash);
    const code = await provider.call(tx, tx.blockNumber);
    const reason = ethers.toUtf8String(`0x${code.slice(138)}`);
    return reason;
  } catch (error) {
    console.error("Error getting transaction failure reason:", error);
    return "Unknown reason";
  }
}

export const calculateLiquidity = async (signer, nasAmount, usdtAmount) => {
  const aquarium = getAquariumContract(signer);
  try {
    const totalSupply = await aquarium.totalSupply();
    if (totalSupply === 0n) {
      // 초기 유동성 제공의 경우
      return BigInt(Math.floor(Math.sqrt(Number(nasAmount * usdtAmount))));
    }
    const liquidity = await aquarium.calculateLiquidity(nasAmount, usdtAmount);
    return liquidity;
  } catch (error) {
    console.error("Liquidity calculation error:", error);
    throw new Error("유동성 계산 중 오류가 발생했습니다.");
  }
};

export const getPrice = async (signer) => {
  const aquarium = getAquariumContract(signer);
  try {
    const price = await aquarium.getPrice();
    return formatUnits(price, 18); // MATIC 단위로 변환
  } catch (error) {
    console.error("Error fetching price:", error);
    return "0";
  }
};

export const getBalances = async (signer) => {
  const nasToken = getNASTokenContract(signer);
  const usdtToken = getUSDTTokenContract(signer);
  const address = await signer.getAddress();

  try {
    const nasBalance = await nasToken.balanceOf(address);
    const usdtBalance = await usdtToken.balanceOf(address);
    return { nasBalance, usdtBalance };
  } catch (error) {
    console.error("Error getting balances:", error);
    return { nasBalance: 0n, usdtBalance: 0n };
  }
};

export const getPoolData = async (signer) => {
  const aquarium = getAquariumContract(signer);

  try {
    const price = await aquarium.getPrice();
    const liquidity = await aquarium.totalSupply();
    const volume = parseUnits("0", 18); // 기본값으로 설정

    return {
      price: formatUnits(price, 18),
      volume: formatUnits(volume, 18),
      liquidity: formatUnits(liquidity, 18),
    };
  } catch (error) {
    console.error('Error fetching pool data:', error);
    throw error;
  }
};

export const getLiquidityEvents = async (signer) => {
  const aquarium = getAquariumContract(signer);
  const filterAdded = aquarium.filters.LiquidityAdded(signer.getAddress());
  const filterRemoved = aquarium.filters.LiquidityRemoved(signer.getAddress());

  const addedEvents = await aquarium.queryFilter(filterAdded, 0, "latest");
  const removedEvents = await aquarium.queryFilter(filterRemoved, 0, "latest");

  return {
    addedEvents,
    removedEvents
  };
};

export const calculatePotentialEarnings = async (signer, nasAmount, usdtAmount) => {
  const aquarium = getAquariumContract(signer);
  try {
    const lpTokens = await calculateLiquidity(signer, nasAmount, usdtAmount);
    const totalSupply = await aquarium.totalSupply();
    const totalFees = await aquarium.totalFees(); // 컨트랙트에서 수집된 총 수수료를 반환하는 함수
    
    const potentialEarnings = lpTokens.mul(totalFees).div(totalSupply);
    return formatUnits(potentialEarnings, 18);
  } catch (error) {
    console.error("Error calculating potential earnings:", error);
    return "N/A";
  }
};

export const getLockRemainingTime = async (signer, index = 0) => {
  const aquarium = getAquariumContract(signer);
  try {
    const userAddress = await signer.getAddress();
    const remainingTime = await aquarium.getLockRemainingTime(userAddress, index);
    return remainingTime.toNumber();
  } catch (error) {
    if (error.message.includes("Invalid index")) {
      console.log("User hasn't provided liquidity yet or invalid index");
      return null;
    }
    console.error("Error fetching lock remaining time:", error);
    throw error;
  }
};

export const getReferralContract = (signer) => {
  return new ethers.Contract(REFERRAL_ADDRESS, REFERRAL_ABI, signer);
};

export const registerReferral = async (signer, referrerAddress) => {
  const referralContract = getReferralContract(signer);
  return await referralContract.registerReferral(referrerAddress);
};

export const getNASBalance = async (signer) => {
  const nasToken = getNASTokenContract(signer);
  const address = await signer.getAddress();
  const balance = await nasToken.balanceOf(address);
  return formatUnits(balance, 18);
};

export const getMinimumNASBalance = async (signer) => {
  const referralContract = getReferralContract(signer);
  const minBalance = await referralContract.minimumNASBalance();
  return formatUnits(minBalance, 18);
};

export const getReferrals = async (signer) => {
  const referralContract = getReferralContract(signer);
  const address = await signer.getAddress();
  const referrer = await referralContract.referrals(address);
  return referrer;
};

export const getActivities = async (signer) => {
  const referralContract = getReferralContract(signer);
  const filter = referralContract.filters.RewardCalculated(null, null, null);
  const events = await referralContract.queryFilter(filter);
  
  return events.map(event => ({
    referrer: event.args.referrer,
    amount: formatUnits(event.args.amount, 18),
    activityType: event.args.activityType
  }));
};

export const getRewards = async (signer) => {
  const referralContract = getReferralContract(signer);
  const address = await signer.getAddress();
  const rewards = await referralContract.referralRewards(address);
  return formatUnits(rewards, 18);
};

export const getRewardPoolContract = (signer) => {
  return new ethers.Contract(REWARD_POOL_ADDRESS, REWARD_POOL_ABI, signer);
};

export const getNaTokenBalance = async (signer) => {
  const nasToken = getNASTokenContract(signer);
  const address = await signer.getAddress();
  const balance = await nasToken.balanceOf(address);
  return formatUnits(balance, 18);
};

export const getTotalRewards = async (signer) => {
  const rewardPoolContract = getRewardPoolContract(signer);
  const address = await signer.getAddress();
  const totalRewards = await rewardPoolContract.getTotalRewards(address);
  return formatUnits(totalRewards, 18);
};

export const getClaimableRewards = async (signer) => {
  const rewardPoolContract = getRewardPoolContract(signer);
  const address = await signer.getAddress();
  const claimableRewards = await rewardPoolContract.getClaimableRewards(address);
  return formatUnits(claimableRewards, 18);
};

export const claimRewards = async (signer) => {
  const rewardPoolContract = getRewardPoolContract(signer);
  const tx = await rewardPoolContract.claimRewards();
  return tx;
};