import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';
import { getAquariumContract, addLiquidity, calculateLiquidity, getPrice, getBalances, getPoolData, getLiquidityEvents, calculatePotentialEarnings, getLockRemainingTime,
  AQUARIUM_ADDRESS,
  NAS_TOKEN_ADDRESS,
  USDT_TOKEN_ADDRESS,
  NAS_TOKEN_ABI,
  USDT_TOKEN_ABI,
  AQUARIUM_ABI } from '../utils/contracts'; 
import { connectWallet } from '../utils/walletUtils';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import aquaV2HeaderImage from '../images/liquidity-header.png';
import nasTokenImage from '../images/nas_token_27.png';
import usdtTokenImage from '../images/usdt_27.png';
import checkImage from '../images/check.png';
import '../pages/AquariumV2.css'

const { formatUnits, parseUnits } = ethers;

const AquariumV2 = () => {
  const [nasAmount, setNasAmount] = useState('');
  const [usdtAmount, setUsdtAmount] = useState('');
  const [lpTokens, setLpTokens] = useState(0);
  const [price, setPrice] = useState(0);
  const [balances, setBalances] = useState({ nasBalance: 0, usdtBalance: 0 });
  const [signer, setSigner] = useState(null);
  const [liquidityHistory, setLiquidityHistory] = useState({ added: [], removed: [] });
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '가격 변동 (USDT)',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        fill: false,
      },
      {
        label: '유동성 규모 (NAS)',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        fill: false,
      },
      {
        label: '거래량 (NAS)',
        data: [],
        borderColor: 'rgb(54, 162, 235)',
        fill: false,
      },
    ],
  });
  const [lockRemainingTime, setLockRemainingTime] = useState(0);
  const [estimatedLpTokens, setEstimatedLpTokens] = useState('0');
  const [minLiquidityAmount, setMinLiquidityAmount] = useState('100');
  const [nasRatio, setNasRatio] = useState(0);
  const [usdtRatio, setUsdtRatio] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState(30); // 기본값 30일
  const [potentialEarnings, setPotentialEarnings] = useState('0');

  useEffect(() => {
    const init = async () => {
      try {
        const connected = await connectWallet();
        if (connected) {
          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          setSigner(signer);

          const contract = new ethers.Contract(AQUARIUM_ADDRESS, AQUARIUM_ABI, signer);
          const nasToken = new ethers.Contract(NAS_TOKEN_ADDRESS, NAS_TOKEN_ABI, signer);
          const usdtToken = new ethers.Contract(USDT_TOKEN_ADDRESS, USDT_TOKEN_ABI, signer);

          try {
            const currentPrice = await getPrice(signer);
            setPrice(currentPrice === "0" ? "유동성 없음" : `${currentPrice} USDT`);
          } catch (error) {
            console.error("Error fetching price:", error);
            setPrice("N/A");
          }

          try {
            const balances = await getBalances(signer);
            setBalances({
              nasBalance: formatUnits(balances.nasBalance, 18),
              usdtBalance: formatUnits(balances.usdtBalance, 6)
            });
          } catch (error) {
            console.error("Error fetching balances:", error);
            setBalances({ nasBalance: "N/A", usdtBalance: "N/A" });
          }

          try {
            const nasRatio = await contract.nasRatio();
            const usdtRatio = await contract.usdtRatio();
            setNasRatio(formatUnits(nasRatio, 18));
            setUsdtRatio(formatUnits(usdtRatio, 6));
          } catch (error) {
            console.error("Error fetching ratios:", error);
          }

          const updateUserLiquidityInfo = async () => {
            try {
              const aquarium = getAquariumContract(signer);
              const userAddress = await signer.getAddress();
              const userLiquidityInfo = await aquarium.getUserLiquidityHistory(userAddress);
              if (userLiquidityInfo.length > 0 && userLiquidityInfo[0].amount.gt(0)) {
                const remainingTime = await getLockRemainingTime(signer, 0);
                setLockRemainingTime(remainingTime);
              } else {
                setLockRemainingTime(0);
                console.log("사용자가 아직 유동성을 제공하지 않았습니다.");
              }
              const { addedEvents, removedEvents } = await getLiquidityEvents(signer);
              setLiquidityHistory({ added: addedEvents, removed: removedEvents });
            } catch (error) {
              console.error("Error updating user liquidity info:", error);
            }
          };  
  
          try {
            const { addedEvents, removedEvents } = await getLiquidityEvents(signer);
            setLiquidityHistory({ added: addedEvents, removed: removedEvents });
          } catch (error) {
            console.error("Error fetching liquidity history:", error);
          }

          try {
            const { price, volume, liquidity } = await getPoolData(signer);
            setChartData((prevState) => ({
              ...prevState,
              labels: [...prevState.labels, new Date().toLocaleTimeString()],
              datasets: [
                { ...prevState.datasets[0], data: [...prevState.datasets[0].data, price] },
                { ...prevState.datasets[1], data: [...prevState.datasets[1].data, liquidity] },
                { ...prevState.datasets[2], data: [...prevState.datasets[2].data, volume] }
              ]
            }));
          } catch (error) {
            console.error("Error fetching pool data:", error);
          }
        }
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    };

    init();
    const interval = setInterval(init, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchContractData = async () => {
      if (signer) {
        const contract = new ethers.Contract(AQUARIUM_ADDRESS, AQUARIUM_ABI, signer);
        const minAmount = await contract.minLiquidityAmount();
        setMinLiquidityAmount(formatUnits(minAmount, 6));
        const nasRatio = await contract.nasRatio();
        const usdtRatio = await contract.usdtRatio();
        setNasRatio(formatUnits(nasRatio, 18));
        setUsdtRatio(formatUnits(usdtRatio, 6));
      }
    };
    fetchContractData();
  }, [signer]);

  useEffect(() => {
    if (usdtAmount && nasRatio && usdtRatio) {
      const calculatedNasAmount = (parseFloat(usdtAmount) * parseFloat(nasRatio)) / parseFloat(usdtRatio);
      setNasAmount(calculatedNasAmount.toFixed(18));
    }
  }, [usdtAmount, nasRatio, usdtRatio]);

  useEffect(() => {
    const previewLpTokens = async () => {
      if (signer && nasAmount && usdtAmount) {
        try {
          const lpAmount = await calculateLiquidity(
            signer,
            parseUnits(nasAmount, 18),
            parseUnits(usdtAmount, 6)
          );
          setLpTokens(formatUnits(lpAmount, 18));
        } catch (error) {
          console.error('Error calculating liquidity:', error);
        }
      }
    };
    previewLpTokens();
  }, [signer, nasAmount, usdtAmount]);

  const handleSupplyLiquidity = async () => {
    if (!signer) {
      alert('지갑을 먼저 연결해주세요.');
      return;
    }

    if (isNaN(usdtAmount) || parseFloat(usdtAmount) <= 0) {
      alert('유효한 USDT 수량을 입력해주세요.');
      return;
    }

    try {
      const miningDuration = BigInt(selectedDuration * 24 * 60 * 60); 

      console.log("Attempting to add liquidity...");
      console.log("USDT amount:", usdtAmount);
      console.log("Mining duration:", miningDuration.toString());

      alert('유동성 추가 트랜잭션을 시작합니다. 지갑에서 확인해주세요.');

      const txHash = await addLiquidity(signer, usdtAmount, miningDuration);
      console.log("Liquidity added, transaction hash:", txHash);

      alert(`트랜잭션이 성공적으로 제출되었습니다: ${txHash}`);

    } catch (error) {
      console.error('유동성 추가 중 오류:', error);
      let errorMessage = '유동성 추가에 실패했습니다.';
      if (error.message) {
        errorMessage += ` 사유: ${error.message}`;
      }
      if (error.transaction) {
        console.error("Failed transaction data:", error.transaction);
        errorMessage += ` 트랜잭션 데이터를 콘솔에서 확인해주세요.`;
      }
      alert(errorMessage);
    }
  };

  const handleCalculateEarnings = async () => {
    if (signer && nasAmount && usdtAmount) {
      try {
        const earnings = await calculatePotentialEarnings(
          signer,
          parseUnits(nasAmount, 18),
          parseUnits(usdtAmount, 6)
        );
        setPotentialEarnings(formatUnits(earnings, 6));
        const lpTokens = await calculateLiquidity(
          signer,
          parseUnits(nasAmount, 18),
          parseUnits(usdtAmount, 6)
        );
        setEstimatedLpTokens(formatUnits(lpTokens, 18));
      } catch (error) {
        console.error('Error calculating earnings:', error);
        setPotentialEarnings("오류 발생");
        alert("수익 계산 중 오류가 발생했습니다. 콘솔을 확인해주세요.");
      }
    } else {
      alert("지갑을 연결하고 USDT 수량을 입력해주세요.");
    }
  };

  return (
    <div className="aquariumv2">
      <section className="aqua1">
        <div className="background-image">
          <img className="aquarium-header" alt="Aquarium header" src={aquaV2HeaderImage} />
        </div>
        <div className="aqua1-content">
          <div className="liquidityPool">
            <div className="text-wrapper">Liquidity Pool 유동성풀</div>
          </div>
          <div className="aquarium-title">
            <h1>아쿠아리움</h1>
          </div>
          <div className="coin-bonus">
            <p className="na">
              아쿠아리움에서 살고있는<br /> 
              물고기들에게 먹이를 주고<br />
              Na. 코인 보너스를 받아보세요!
            </p>
          </div>
        </div>
      </section>

      <section className="aqua2">
        <div className="liquidity-box">
          <div className="token-input">
           <div className="token-info">
            <img src={usdtTokenImage} alt="USDT Token" />
            <div className="token-details">
              <span className="token-name">USDT</span>
              <span className="required">*필수입력</span>
              <span className="balance" title={`USDT 잔액: ${balances.usdtBalance}`}>USDT 잔액: {balances.usdtBalance}</span>
            </div>
           </div>
              <input
              type="number"
              value={usdtAmount}
              onChange={(e) => setUsdtAmount(e.target.value)}
              placeholder={`최소 ${minLiquidityAmount} USDT`}
              className="token-input-field"
            />
          </div>
          <div className="token-input">
           <div className="token-info">
            <img src={nasTokenImage} alt="NAS Token" className="nas-token-image" />
            <div className="token-details">
              <span className="token-name">NAS</span>
              <span className="required">*필수입력</span>
              <span className="balance" title={`NAS 잔액: ${balances.nasBalance}`}>NAS 잔액: {balances.nasBalance}</span>
              </div>
           </div>
            <input
              type="number"
              value={nasAmount}
              onChange={(e) => setNasAmount(e.target.value)}
              placeholder="NAS 수량"
              readOnly
              className="token-input-field"
            />
          </div>
          <div className="duration-selection-container">
            <span>⎈ 공급일 선택</span>
            <div className="duration-selection">
            {[30, 60, 90, 120, 150, 180].map((days) => (
              <button
                key={days}
                onClick={() => setSelectedDuration(days)}
                className={`duration-button ${selectedDuration === days ? 'selected' : ''}`}
              >
                {days}일
              </button>
            ))}
          </div>
          </div>
          <p className="warning">*주의 : 30일 출금 제한이 적용됩니다.</p>
          <button onClick={handleSupplyLiquidity} className="supply-button">💰 유동성 공급하기</button>
          <button onClick={handleCalculateEarnings} className="preview-button">Na. 보너스 미리보기</button>
          {estimatedLpTokens !== '0' && <p>예상 Na(LP) 토큰 수량: {estimatedLpTokens}</p>}
        </div>
      </section>
      
      <section className="aqua3">
      <div className="info-box">
        <div className="info-box-header">
          <img src={checkImage} alt="Check" className="check-image" />
          <h3>유동성 채굴이란?</h3>
        </div>  
          <p>'Do! at NAS'의 유동성 채굴은 바다 깊은 곳에서<br />
          진귀한 보물을 찾는 모험과 같습니다.<br />
          예치 기간이 길어질수록, 여러분의 기여도는 더 커지고<br />
          그에 따라 더 많은 LP보상을 받게 됩니다.<br />
          더 긴 항해는 더 큰 보물과 이어집니다!⛴️<br />
           </p>
        </div>
        <div className="chart">
          <div className="chart-header">
          <img src={checkImage} alt="Check" className="check-image" />
          <h3>유동성풀 데이터</h3>
          </div>
          <Line data={chartData} />
        </div>
        <div className="liquidity-history">
          <h4>⎈ 나의 유동성풀 이력보기</h4>
          <div>
            <h5>▪︎ 유동성 추가 기록</h5>
            <ul>
              {liquidityHistory.added.map((event, index) => (
                <li key={index}>
                  NAS: {formatUnits(event.args.nasAmount, 18)}, USDT: {formatUnits(event.args.usdtAmount, 6)}, 받은 LP: {formatUnits(event.args.liquidity, 18)}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h5>▪︎ 유동성 제거 기록</h5>
            <ul>
              {liquidityHistory.removed.map((event, index) => (
                <li key={index}>
                  NAS: {formatUnits(event.args.nasAmount, 18)}, USDT: {formatUnits(event.args.usdtAmount, 6)}, 받은 LP: {formatUnits(event.args.liquidity, 18)}
                </li>
              ))}
            </ul>
          </div>
         <div className="withdrawal-date">
            <h6>⎈ 출금 가능한 날짜</h6>
            <p>{new Date(Date.now() + lockRemainingTime * 1000).toLocaleDateString()}</p>
         </div> 
        </div>   
      </section>
      <Link to="/" className="main-link">홈 화면으로 돌아가기</Link>
    </div>
  );
};

export default AquariumV2;