//Game.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../pages/Game.css';

function Game() {
  return (
    <div className="game-page">
      <div className="game-title">
        <h1 className="title-text">Game</h1>
      </div>
      <a href="https://lake-613.github.io/WDOA_GAME/" target="_blank" rel="noopener noreferrer" className="game-link">
        게임하러 가기
      </a>
      <Link to="/" className="main-link">홈 화면으로 돌아가기</Link>
    </div>
  );
}

export default Game;
