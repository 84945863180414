import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';
import { 
  registerReferral, 
  getNASBalance, 
  getMinimumNASBalance,
  getReferrals,
  getActivities,
  getRewards,
  claimRewards, 
  getClaimableRewards, 
  getNaTokenBalance, 
  getTotalRewards 
} from '../utils/contracts';
import referralBackImage from '../images/referral_back.png';
import naTokenImage from '../images/na_27.png';
import './Referral.css';

const Referral = () => {
  const [referrals, setReferrals] = useState([]);
  const [activities, setActivities] = useState([]);
  const [rewards, setRewards] = useState(0);
  const [referrerAddress, setReferrerAddress] = useState('');
  const [message, setMessage] = useState('');
  const [nasBalance, setNasBalance] = useState(0);
  const [minimumNASBalance, setMinimumNASBalance] = useState(0);
  const [naBalance, setNaBalance] = useState('0');
  const [claimableRewards, setClaimableRewards] = useState('0');
  const [totalRewards, setTotalRewards] = useState('0');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();

        // NAS 토큰 잔액 확인
        const nasBalanceResult = await getNASBalance(signer);
        setNasBalance(ethers.formatEther(nasBalanceResult));

        // Na 토큰 잔액 확인
        const naBalanceResult = await getNaTokenBalance(signer);
        setNaBalance(naBalanceResult);

        // 최소 NAS 잔액 요구사항 가져오기
        const minBalance = await getMinimumNASBalance(signer);
        setMinimumNASBalance(ethers.formatEther(minBalance));

        // 레퍼럴 정보 가져오기
        const referralData = await getReferrals(signer);
        setReferrals(referralData);

        // 활동 내역 가져오기
        const activityData = await getActivities(signer);
        setActivities(activityData);

        // 보상 정보 가져오기
        const rewardData = await getRewards(signer);
        setRewards(ethers.formatEther(rewardData));

        // 청구 가능한 보상 가져오기
        const claimableRewardsResult = await getClaimableRewards(signer);
        setClaimableRewards(claimableRewardsResult);

        // 총 획득한 보상 가져오기
        const totalRewardsResult = await getTotalRewards(signer);
        setTotalRewards(totalRewardsResult);

      } catch (error) {
        console.error("데이터 불러오기 실패:", error);
        setMessage("데이터를 불러오는 데 실패했습니다. 다시 시도해주세요.");
      }
    };

    fetchData();
  }, []);

  const handleRegisterReferral = async () => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      // NAS 토큰 잔액 확인
      const balance = await getNASBalance(signer);
      const minBalance = await getMinimumNASBalance(signer);

      if (ethers.parseEther(balance).lt(ethers.parseEther(minBalance))) {
        setMessage(`레퍼럴 보상을 받으려면 최소 ${minBalance} NAS 토큰이 필요합니다.`);
        return;
      }

      const tx = await registerReferral(signer, referrerAddress);
      await tx.wait();
      setMessage('추천인이 성공적으로 등록되었습니다!');

      // 등록된 추천인 목록 업데이트
      const updatedReferrals = await getReferrals(signer);
      setReferrals(updatedReferrals);

      setReferrerAddress(''); // 입력 필드 초기화
    } catch (error) {
      setMessage('추천인 등록 중 오류가 발생했습니다. 다시 시도해주세요.');
      console.error(error);
    }
  };

  const handleClaim = async () => {
    try {
      setMessage('보상 청구 중...');
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      const tx = await claimRewards(signer);
      await tx.wait();

      setMessage('보상이 성공적으로 청구되었습니다!');

      // 보상 정보 업데이트
      const updatedRewards = await getRewards(signer);
      setRewards(ethers.formatEther(updatedRewards));

      // Na 토큰 잔액 업데이트
      const updatedNaBalance = await getNaTokenBalance(signer);
      setNaBalance(updatedNaBalance);

      // 청구 가능한 보상 업데이트
      const updatedClaimableRewards = await getClaimableRewards(signer);
      setClaimableRewards(updatedClaimableRewards);

      // 총 획득한 보상 업데이트
      const updatedTotalRewards = await getTotalRewards(signer);
      setTotalRewards(updatedTotalRewards);

    } catch (error) {
      console.error('보상 청구 중 오류 발생:', error);
      setMessage('보상 청구에 실패했습니다. 다시 시도해주세요.');
    }
  };

  return (
    <div className="referral">
      <div className="back-image">
        <img className="referral-back-img" alt="referral back" src={referralBackImage} />
      </div>
      <div className="content-wrapper">
        <div className="referral-intro">Referral 레퍼럴</div>
        <h2>추천 보너스</h2>
        <div className="friend">친구에게 <span className="highlight">Do! at NAS</span>를 소개하고<br />
         토큰 보상을 받으세요!</div>
        <div className="strong-reward">강력한 추천 리워드와 함께 멋진 탐험을 해보세요.</div>
        <div className="referral-box">
          <h4>⎈ 나의 추천인 등록</h4>
          <input
            type="text"
            placeholder="추천인 지갑 주소 입력"
            value={referrerAddress}
            onChange={(e) => setReferrerAddress(e.target.value)}
          />
          <button onClick={handleRegisterReferral}>추천 등록</button>
          {message && <p className="message">{message}</p>}
          <h4>⎈ 내가 추천한 사람들</h4>
          <ul className="referral-list">
            {referrals.map((referral, index) => (
              <li key={index}>{referral}</li>
            ))}
          </ul>
          <h4>⎈ 활동 내역</h4>
          <select className="activity-select">
            <option value="all">전체</option>
            <option value="aquarium">유동성풀</option>
            <option value="dex">DEX</option>
            <option value="other">기타</option>
          </select>
          <ul className="activity-list">
            {activities.map((activity, index) => (
              <li key={index}>{activity}</li>
            ))}
          </ul>
          <h4>⎈ 보상</h4>
        <div className="reward-container">
          <img className="natoken-img" alt="natoken" src={naTokenImage} />
          <p>Na 토큰 잔액: {naBalance} Na</p>
        </div>
        <p>청구 가능한 보상: {claimableRewards} Na</p>
        <p>총 획득한 보상: {totalRewards} Na</p>
        <button onClick={handleClaim} disabled={parseFloat(claimableRewards) <= 0}>
          보상 청구
        </button>
        {message && <p className="message">{message}</p>}
      </div>
        </div>
        <Link to="/" className="main-link">홈 화면으로 돌아가기</Link>
    </div>
  );
};

export default Referral;