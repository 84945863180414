import React from 'react';
import { Link } from 'react-router-dom';
import WalletButton from '../components/WalletButton';
import './Home.css';
import homeHeaderImage from '../images/home_header.png';
import octopusImage from '../images/octopus.png';
import aquariumImage from '../images/aquarium.png';
import gameImage from '../images/game.png'; 
import referralImage from '../images/referral.png';
import swapImage from '../images/swap.png';
import footerImage from '../images/home_footer.png';
import logoImage from '../images/nas_180.png'


//home1
const Header = () => (
  <header className="home-header">
    <div className="background-image">
      <img className="home-header-img" alt="Home header" src={homeHeaderImage} />
    </div>
    <div className="header-content">
      <NASDevelop />
      <NASIntro />
      <WalletButton className="top-wallet-button" />
    </div>
  </header>
);

const NASDevelop = () => (
  <div className="nas-develop">
    <h1>
      <span className="shadow-text">N</span>autical<br />
      <span className="shadow-text">A</span>musement<br />
      <span className="shadow-text">S</span>ite<br />
    </h1>
  </div>
);

const NASIntro = () => (
  <div className="nas-intro">
    <p className="NAS">
      <span className="text-wrapper">
        탈중앙화 금융 생태계가 펼쳐지는 드넓은 바다 <br />
      </span>
      <span className="span">즐거운 거래가 시작되는 곳, </span><br />
      <span className="text-wrapper">NAS에 오신 것을 환영합니다.</span>
    </p>
  </div>
);

//home2
const About = () => (
  <div className="about">
    <div className="text-wrapper">Do! at NAS</div>
  </div>
);

const Together = () => (
  <div className="together">
    <p className="together-nas">
      <span className="text-wrapper">모두가 함께 중개자 없이</span><br />
      <span className="text-wrapper">탈중앙화 금융 생태계를 만드는 곳</span><br />
      <span className="text-wrapper">NAS로 시작하는 DEX 탐험!</span>
    </p>
  </div>
);

const Platform = () => (
  <div className="platform">
    <p className="text-wrapper">혁신적인 거래 플랫폼 NAS에 오신 것을 환영합니다.</p>
  </div>
);

//staking
const Octopus = () => (
  <div className="image">
    <img className="octopus" alt="octopus" src={octopusImage} />
  </div>
);

const StakingEnglish = () => (
  <div className="staking-english">
    <div className="text-wrapper">Staking</div>
  </div>
);

const StakingKorean = () => (
  <div className="staking-korean">
    <div className="text-wrapper">스테이킹</div>
  </div>
);

const StakingYield = () => (
  <div className="staking-yield">
    <div className="flexcontainer">
      <p className="text">
        <span className="text-wrapper">
          코인 예치하고
          <br />
        </span>
      </p>
      <p className="text">
        <span className="text-wrapper">이자 받기</span>
      </p>
    </div>
  </div>
);

const Navigation = ({ to, children }) => (
  <Link to={to} className="navigation">
    <div className="text-wrapper">{children} &gt;</div>
  </Link>
);

// 사용 예시
const StakingGroup = () => (
  <div className="feature-group staking-group">
    <Octopus />
    <StakingEnglish />
    <StakingKorean />
    <StakingYield />
    <Navigation to="/staking">바로가기 &gt;</Navigation>
  </div>
);

//aquarium
const Aquarium = () => (
  <div className="image">
    <img className="aquarium" alt="aquarium" src={aquariumImage} />
  </div>
);

const AquariumEng = () => (
  <div className="aquarium-eng">
    <div className="text-wrapper">Liquidity</div>
  </div>
);

const AquariumKo = () => (
  <div className="aquarium-ko">
    <div className="text-wrapper">아쿠아리움</div>
  </div>
);

const Commission = () => (
  <div className="commission">
    <div className="flexcontainer">
      <p className="text">
        <span className="text-wrapper">
          코인 공급하고
          <br />
        </span>
      </p>
      <p className="text">
        <span className="text-wrapper">수수료 받기</span>
      </p>
    </div>
  </div>
);

const LiquidityGroup = () => (
  <div className="feature-group liquidity-group">
    <Aquarium />
    <AquariumEng />
    <AquariumKo />
    <Commission />
    <Navigation to="/aquarium">바로가기 &gt;</Navigation>
  </div>
);

//game
const Game = () => (
  <div className="image">
    <img className="game" alt="game" src={gameImage} />
  </div>
);

const GameEng = () => (
  <div className="game-eng">
    <div className="text-wrapper">Game</div>
  </div>
);

const GameKo = () => (
  <div className="game-ko">
    <div className="text-wrapper">게임</div>
  </div>
);

const Join = () => (
  <div className="join">
    <div className="flexcontainer">
      <p className="text">
        <span className="text-wrapper">
          게임 참여하고
          <br />
        </span>
      </p>
      <p className="text">
        <span className="text-wrapper">코인 받기</span>
      </p>
    </div>
  </div>
);

const GameGroup = () => (
  <div className="feature-group game-group">
    <Game />
    <GameEng />
    <GameKo />
    <Join />
    <Navigation to="/game">바로가기 &gt;</Navigation>
  </div>
);

//referral
const Referral = () => (
  <div className="image">
    <img className="referral" alt="Referral" src={referralImage} />
  </div>
);

const ReferralEng = () => (
  <div className="referral-eng">
    <div className="text-wrapper">Referral</div>
  </div>
);

const ReferralKo = () => (
  <div className="referral-ko">
    <div className="text-wrapper">소개</div>
  </div>
);

const Reward = () => (
  <div className="reward">
    <div className="flexcontainer">
      <p className="text">
        <span className="text-wrapper">
          친구에게 소개하고
          <br />
        </span>
      </p>
      <p className="text">
        <span className="text-wrapper">보상받기</span>
      </p>
    </div>
  </div>
);

const ReferralGroup = () => (
  <div className="feature-group referral-group">
    <Referral />
    <ReferralEng />
    <ReferralKo />
    <Reward />
    <Navigation to="/referral">바로가기 &gt;</Navigation>
  </div>
);

//Swap
const Swap = () => (
  <div className="image">
    <img className="swap" alt="swap" src={swapImage} />
  </div>
);

const SwapEng = () => (
  <div className="swap-eng">
    <div className="text-wrapper">Swap</div>
  </div>
);

const SwapKo = () => (
  <div className="swap-ko">
    <div className="text-wrapper">거래소</div>
  </div>
);

const Transfer = () => (
  <div className="transfer">
      <div className="text-wrapper">코인 교환하기</div>
  </div>
);

const SwapGroup = () => (
  <div className="feature-group swap-group">
    <Swap />
    <SwapEng />
    <SwapKo />
    <Transfer />
    <Navigation to="/dex">바로가기 &gt;</Navigation>
  </div>
);

// home3
const Footer = () => (
  <footer className="footer">
    <div className="footer-image">
      <img alt="footer" src={footerImage} />
    </div>
    <div className="footer-content">
      <Echo />
      <NASToken />
      <Earth />
      <OneDoor />
      <Logo />
      <Fishing />
      <WalletButton className="bottom-wallet-button" />
    </div>
  </footer>
);

const Echo = () => (
  <div className="echo">
    <p className="text-wrapper">친환경 채굴 솔루션으로 실물 경제를 변화시키는 코인</p>
  </div>
);

const NASToken = () => (
  <div className="nas-token">
    <div className="text-wrapper">NAS</div>
  </div>
);

const Earth = () => (
  <div className="earth">
    <div className="flexcontainer">
      <p className="text">
        <span className="text-wrapper">
          NAS는 지구를 보호할 뿐만 아니라
        </span>
      </p>
      <p className="text">
        <span className="text-wrapper">
          교육 및 재정적 성장을 통해 지역 사회에 힘을 실어줍니다.
        </span>
      </p>
    </div>
  </div>
);

const OneDoor = () => (
  <div className="one-door">
    <div className="flexcontainer">
      <p className="text">
        <span className="text-wrapper">
          친환경 채굴, 자선 활동, 재정적 확장을
        </span>
      </p>
      <p className="text">
        <span className="text-wrapper">
          통합하기 위해 리브랜딩된 NAS는
        </span>
      </p>
      <p className="text">
        <span className="text-wrapper">
          단순한 코인이 아니라
        </span>
      </p>
      <p className="text">
        <span className="span">더 공정하고 밝은 미래로 가는 하나의 문</span>
        <span className="text-wrapper">입니다.</span>
      </p>
    </div>
  </div>
);

const Logo = () => (
  <div className="image">
    <img className="logo" alt="logo" src={logoImage} />
  </div>
);

const Fishing = () => (
  <div className="fishing">
    <p className="text-wrapper">막 바다에서 채굴하여 건져 올린 </p>
    <p className="text-wrapper">신선한 NAS 담아가실래요? </p>
  </div>
);

function Home() {
  return (
    <div className="home-container">
      <div className="home-content">
        <Header />
        <main className="main">
          <About />
          <Together />
          <Platform />
          <div className="features-container">
            <div className="features-row">
              <StakingGroup />
              <LiquidityGroup />
            </div>
            <div className="features-row">
              <GameGroup />
              <ReferralGroup />
            </div>
            <div className="features-row">
              <SwapGroup />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Home;