//Staking.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../pages/Staking.css';

const StakingHeader = () => (
  <div className="staking-header">
    <div className="staking-eng">Staking</div>
    <div className="staking-ko">스테이킹</div>
    <div className="yield">
      <p>코인을 예치하고 이자를 받아가세요!</p>
    </div>
  </div>
);

const ComingSoon = () => (
  <div className="coming-soon">
    <p>준비중!</p>
  </div>
);

function Staking() {
  return (
    <div className="staking">
      <StakingHeader />
      <div className="staking-content">
        <ComingSoon />
      </div>
      <div className="main-link-container">
        <Link to="/" className="main-link">홈 화면으로 돌아가기</Link>
      </div>
    </div>
  );
}

export default Staking;