import { ethers } from 'ethers';

export const getBalance = async (address, provider) => {
  try {
    const balance = await provider.getBalance(address);
    return ethers.formatEther(balance); // MATIC 단위로 변환
  } catch (error) {
    console.error("잔액 조회 오류:", error);
    return null;
  }
};

// 메타데이터 URI 설정 함수
export const setMetadataURI = async (contract, uri, signer) => {
  try {
    const tx = await contract.connect(signer).setMetadataURI(uri);
    await tx.wait();
    console.log('Metadata URI set successfully');
    return true;
  } catch (error) {
    console.error("메타데이터 URI 설정 오류:", error);
    return false;
  }
};

// 메타데이터 URI 조회 함수 (선택적)
export const getMetadataURI = async (contract) => {
  try {
    const uri = await contract.metadataURI();
    return uri;
  } catch (error) {
    console.error("메타데이터 URI 조회 오류:", error);
    return null;
  }
};

// 스마트 컨트랙트 인스턴스 생성 함수
export const getContract = (address, abi, signerOrProvider) => {
  return new ethers.Contract(address, abi, signerOrProvider);
};

// BigInt 변환 함수 (기존의 BigNumber 대체)
export const convertToBigInt = (value) => {
  return BigInt(value);
};

// BigInt를 문자열로 포맷팅 (UI 표시용)
export const formatBigInt = (value, decimals = 18) => {
  return ethers.formatUnits(value, decimals);
};

// 문자열을 BigInt로 파싱
export const parseBigInt = (value, decimals = 18) => {
  return ethers.parseUnits(value, decimals);
};

// BigInt 산술 연산 유틸리티 함수들
export const addBigInt = (a, b) => a + b;
export const subtractBigInt = (a, b) => a - b;
export const multiplyBigInt = (a, b) => a * b;
export const divideBigInt = (a, b) => a / b;