import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { EthersProvider } from './EthersProvider'; 
import Home from './pages/Home';
import AquariumV2 from './pages/AquariumV2';
import DEX from './pages/DEX';
import Referral from './pages/Referral';
import Game from './pages/Game';
import Staking from './pages/Staking';

function App() {
  return (
    <EthersProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aquarium" element={<AquariumV2 />} />
            <Route path="/dex" element={<DEX />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/game" element={<Game />} />
            <Route path="/staking" element={<Staking />} />
          </Routes>
        </div>
      </Router>
    </EthersProvider>
  );
}

export default App;