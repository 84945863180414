// EthersProvider.js
import React, { createContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';

export const EthersContext = createContext(null);

export const EthersProvider = ({ children }) => {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        setProvider(provider);
        setSigner(signer);
        setIsConnected(true);
      } catch (error) {
        console.error('Failed to connect wallet:', error);
        setIsConnected(false);
      }
    } else {
      console.error('MetaMask is not installed');
      setIsConnected(false);
    }
  };

  useEffect(() => {
    connectWallet();
  }, []);

  return (
    <EthersContext.Provider value={{ provider, signer, isConnected, connectWallet }}>
      {children}
    </EthersContext.Provider>
  );
};