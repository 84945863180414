import { ethers } from 'ethers';

let provider;
let signer;

export const initProvider = async () => {
  if (window.ethereum) {
    provider = new ethers.BrowserProvider(window.ethereum);
    try {
      await provider.send("eth_requestAccounts", []);
      signer = await provider.getSigner();
      return true;
    } catch (error) {
      console.error("User denied account access");
      return false;
    }
  } else {
    console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    return false;
  }
};

export const connectWallet = async () => {
  if (!provider) {
    const initialized = await initProvider();
    if (!initialized) return false;
  }
  
  try {
    signer = await provider.getSigner();
    return true;
  } catch (error) {
    console.error("Error connecting wallet:", error);
    return false;
  }
};

export const disconnectWallet = async () => {
  provider = null;
  signer = null;
  return true;
};

export const switchToPolygon = async () => {
  const polygonChainId = '0x89'; // Polygon Mainnet
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: polygonChainId }],
    });
    return true;
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: polygonChainId,
            chainName: 'Polygon Mainnet',
            nativeCurrency: {
              name: 'MATIC',
              symbol: 'MATIC',
              decimals: 18
            },
            rpcUrls: ['https://polygon-rpc.com/'],
            blockExplorerUrls: ['https://polygonscan.com/']
          }],
        });
        return true;
      } catch (addError) {
        console.error('Error adding Polygon network:', addError);
      }
    }
    console.error('Failed to switch to the Polygon network:', switchError);
    return false;
  }
};

export const getAddress = async () => {
  if (signer) {
    return await signer.getAddress();
  }
  return null;
};

export const getBalance = async (address) => {
  if (provider) {
    try {
      const balance = await provider.getBalance(address);
      return ethers.formatEther(balance);
    } catch (error) {
      console.error("Error fetching balance:", error);
      return null;
    }
  }
  return null;
};